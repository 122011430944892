import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import CheckIcon from "@material-ui/icons/Check";

import { useAppState } from "../../../state";
import { useAudioOutputDevices } from "../../MenuBar/DeviceSelector/deviceHooks/deviceHooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  }),
);

export default function DeviceButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const btnLabel = t("Device");
  const [state, setState] = React.useState(false);
  const handleDeviceListShown = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)
    ?.label;

  const list = () => (
    <div
      className="inputSelect"
      onClick={handleDeviceListShown(false)}
      onKeyDown={handleDeviceListShown(false)}
      style={{ textAlign: "center" }}>
      <img
        style={{ width: "2.8em", height: "1.4em", marginTop: "0.5em" }}
        src={require("../../../assets/icons/popup_arrow.png")}
        alt=""
      />

      <List>
        {audioOutputDevices.length > 0 ? (
          audioOutputDevices.map((device, index) => (
            <div key={device.deviceId}>
              <ListItem
                button
                key={device.deviceId}
                alignItems="flex-start"
                onClick={() => setActiveSinkId(device.deviceId)}>
                {/* <ListItemAvatar>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemAvatar> */}
                <ListItemText style={{ fontSize: "1.1rem" }} primary={device.label} />
                {device.deviceId === activeSinkId ? (
                  <ListItemIcon>
                    <CheckIcon style={{ fontSize: "1.1rem" }} />
                  </ListItemIcon>
                ) : null}
              </ListItem>
              {index !== audioOutputDevices.length - 1 ? (
                <Divider light style={{ marginLeft: "1em", marginRight: "1em" }} />
              ) : null}
            </div>
          ))
        ) : (
          <div>
            <ListItem button alignItems="flex-start">
              <ListItemText
                style={{ fontSize: "1.1rem" }}
                primary={activeOutputLabel || t("Default Audio Output")}
              />
              <ListItemIcon>
                <CheckIcon style={{ fontSize: "1.1rem" }} />
              </ListItemIcon>
            </ListItem>
          </div>
        )}
      </List>
    </div>
  );

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip title={btnLabel} placement="top" PopperProps={{ disablePortal: true }}>
        <Fab
          className={classes.fab}
          style={{
            backgroundColor: "rgba(255,255,255,0.04)",
            border: "1px solid",
            borderColor: "rgba(255,255,255,0.2)",
          }}
          onClick={handleDeviceListShown(true)}
          disabled={props.disabled}>
          <img
            src={require("../../../assets/icons/iconFunctionDeviceDefault24@2x.png")}
            alt=""
            style={{ width: "1.5em", height: "1.5em" }}
          />
        </Fab>
      </Tooltip>
      <div style={{ fontSize: "0.8em", fontWeight: 500 }}>{btnLabel}</div>
      <Drawer
        PaperProps={{
          style: { borderRadius: "0.5em", backgroundColor: "rgb(34,34,34)" },
        }}
        anchor="bottom"
        open={state}
        onClose={handleDeviceListShown(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
