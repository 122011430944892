import React from "react";
import { createStyles, makeStyles, Theme, rgbToHex } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import CallEnd from "@material-ui/icons/CallEnd";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: "rgb(237, 27, 46)",
    },
    buttonStyle: {
      margin: theme.spacing(1),
      width: "90%",
      borderRadius: theme.spacing(1),
    },
    buttonCancelStyle: {
      margin: theme.spacing(1),
      width: "90%",
      borderRadius: theme.spacing(1),
      borderColor: "black",
      backgroundColor: "white",
      color: "black",
      marginBottom: theme.spacing(2),
    },
  }),
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndEndCall = () => {
    setOpen(false);
    room.disconnect();
    window.close();
  };

  const btnLabel = t("Leave");
  const confirmLabel = t("Are you sure to leave?");

  return (
    <div style={{ backgroundColor: "transparant", textAlign: "center" }}>
      <Tooltip
        title={btnLabel}
        onClick={handleClickOpen}
        placement="top"
        PopperProps={{ disablePortal: true }}>
        <Fab className={classes.fab} color="primary">
          {/* <CallEnd /> */}
          <img
            src={require("../../../assets/icons/iconFunctionLeaveDefault24@2x.png")}
            alt=""
            style={{ width: "1.5em", height: "1.5em" }}></img>
        </Fab>
      </Tooltip>
      <div style={{ fontSize: "0.8em", fontWeight: 500 }}>{btnLabel}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}>
        {/* <DialogTitle style={{backgroundColor:'white'}} id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText
            style={{ color: "black", textAlign: "center", fontWeight: 500 }}
            id="alert-dialog-description">
            {confirmLabel}
          </DialogContentText>
        </DialogContent>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button
            onClick={handleCloseAndEndCall}
            color="primary"
            variant="contained"
            className={classes.buttonStyle}>
            {btnLabel}
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            autoFocus
            className={classes.buttonCancelStyle}>
            {t("Cancel")}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
