import React, { useCallback, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";

import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: "rgba(255,255,255,0.04)",
    },
  }),
);

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { t } = useTranslation();
  const btnLabel = t("Video");
  const videoOn = t("Turn camera on");
  const videoOff = t("Turn camera off");

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip
        title={isVideoEnabled ? videoOff : videoOn}
        placement="top"
        PopperProps={{ disablePortal: true }}>
        <Fab
          className={classes.fab}
          style={{
            backgroundColor: "rgba(255,255,255,0.04)",
            border: "1px solid",
            borderColor: "rgba(255,255,255,0.2)",
          }}
          onClick={toggleVideo}
          disabled={props.disabled}>
          {isVideoEnabled ? (
            <img
              src={require("../../../assets/icons/iconFunctionVideoDefault24@2x.png")}
              alt=""
              style={{ width: "1.5em", height: "1.5em" }}
            />
          ) : (
            <img
              src={require("../../../assets/icons/iconFunctionVideoSelected24@2x.png")}
              alt=""
              style={{ width: "1.5em", height: "1.5em" }}
            />
          )}
        </Fab>
      </Tooltip>
      <div style={{ fontSize: "0.8em", fontWeight: 500 }}>{btnLabel}</div>
    </div>
  );
}
