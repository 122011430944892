import isPlainObject from 'is-plain-object';
import envConfig from "../envConfig.js";
import { get } from "lodash";

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(devices => devices.every(device => !(device.deviceId && device.label)))
    .then(shouldAskForMediaPermissions => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(mediaStream => mediaStream.getTracks().forEach(track => track.stop()));
      }
    });
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}
export const getQueryPfaram = (queryName: string, strSearch?: string) => {
  let reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
  const search = strSearch || window.location.search.substr(1);
  let r = search.match(reg);
  try {
    if (r != null) {
      return decodeURI(r[2]);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};


export async function getKey(roomName: string, startTime: string, endTime: string) {
  const headers = new window.Headers();
  const host = get(envConfig, "COMMON_PUBLIC_FRONTEND_ROOT_PATH", "");
  const url = `${host}/api/interview/key?roomName=${roomName}&startTime=${startTime}&endTime=${endTime}`;
  // const url = `http://192.168.18.114:3001/interview/key?roomName=${roomName}&startTime=${startTime}&endTime=${endTime}`;
  let data = await fetch(url, { headers }).then(res => res.json());
  return data.data.key;
};
