import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import qs from "querystring";

export function getQuery() {
  if (typeof window !== `undefined`) {
    return qs.parse(window.location.search.substr(1)) || {};
  }
  return {};
}

export function getLanguage(langKey: string = ""): string {
  const query = getQuery();
  let lang: string = langKey || (Array.isArray(query.lang) ? query.lang[0] : query.lang) || "en";
  lang = lang.toLowerCase();
  const langMapping: { [key: string]: string } = {
    en: "en",
    enus: "en-US",
    zh: "zh-Hant", // 默认繁体中文
    zhhk: "zh-Hant", // 默认繁体中文
    "en-us": "en-US",
    "zh-hans": "zh-Hant", // 简体中文(zh-hans), 暂无简体，显示为默认繁体
    "zh-hant": "zh-Hant", // 繁体中文
  };
  // mapping confluence page:https://collaborate.pruconnect.net/pages/viewpage.action?spaceKey=PCASPTL&title=RESTful+API+Guideline
  return langMapping[lang] || "en-US";
}

const langKey = getLanguage(); // read language setting from url param: http://xxx.com?lang?=en

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en-US",
    resources: {
      "zh-Hant": { translation: require("./locale/zh-Hant.json") },
      "en-US": { translation: require("./locale/en-US.json") },
    },
    lng: langKey,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: { escapeValue: false }, // react already safes from xss
  });

export default i18n;
