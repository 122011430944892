import React from "react";
import { LocalVideoTrack, VideoTrack as TVideoTrack } from "twilio-video";
import VideoTrack from "../VideoTrack/VideoTrack";
import { styled } from "@material-ui/core/styles";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column"
}));

const Mask = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(0,0,0,0.5)"
}));

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track =>
    track.name.includes("camera")
  ) as LocalVideoTrack;

  return videoTrack ? (
    <Container>
      <VideoTrack track={videoTrack} isLocal priority={"high"} /> <Mask />
    </Container>
  ) : null;
}
