import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';
// import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
// import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
// import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
// import Menu from './Menu/Menu';

import { useAppState } from "../../state";
import { useParams } from "react-router-dom";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { Typography, Input } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import "./MenuBar.css";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "transparent",
    },
    toolbar: {
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "center",
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, 35vh)",
      zIndex: 1,
      [theme.breakpoints.up("md")]: {
        marginLeft: "2.2em",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(12),
      maxWidth: "20em",
      color: "#fff",
      backgroundColor: "transparent",
    },
    loadingSpinner: {
      width: "5em",
      height: "5em",
      zIndex: 1,
    },
    displayName: {
      margin: "1.1em 0.6em",
      minWidth: "200px",
      fontWeight: 600,
    },
    joinButton: {
      margin: "1em",
      width: "20em",
    },
    disabledButton: {
      margin: "1em",
      width: "20em",
      color: "#fff !important",
      border: "1px solid #fff",
      backgroundColor: "transparent !important",
    },
  }),
);
interface MenuBarProps {
  urlToken: string;
}

export default function MenuBar({ urlToken }: MenuBarProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const connectingLabel = t("Connecting...");
  const btnJoinLabel = t("Join The Meeting");
  const inputPlaceholder = t("Please input your name");
  const joinAlert = t("Join Alert");

  const { URLRoomName, URLUserName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || "");
  const [roomName, setRoomName] = useState<string>("");
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deviceCount, setDeviceCount] = useState(0);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  useEffect(() => {
    if (URLUserName) {
      setName(URLUserName);
    }
  }, [URLUserName]);

  useEffect(() => {
    if (URLRoomName && URLUserName && urlToken) {
      getTokenAndConnect(URLUserName, URLRoomName, urlToken);
      // connect(urlToken);
    }
  }, [URLUserName, URLRoomName]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      console.log("devices init=", devices.length);
      setDeviceCount(devices.length);
    });

    const reconn = () => {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const len = devices.length;
        console.log("devices change=", len, deviceCount - len);
        if (len < deviceCount) {
          window.location.reload();
        } else {
          setDeviceCount(len);
        }
      });
    };
    navigator.mediaDevices.addEventListener("devicechange", reconn);
    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", reconn);
    };
  }, [deviceCount]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const getTokenAndConnect = (userName: string, roomName: string, authKey: string) => {
    getToken(userName, roomName, authKey).then(token => {
      connect(token);
    });
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDialogOpen(true);
  };

  const handleOk = () => {
    setDialogOpen(false);
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes("twil.io")) {
      window.history.replaceState(
        null,
        "",
        window.encodeURI(
          `${process.env.PUBLIC_URL}/${roomName}/${name}${window.location.search || ""}`,
        ),
      );
    }
    getTokenAndConnect(name, roomName, urlToken);
  };

  // console.log("isConnecting===",isConnecting)
  // console.log("roomState===",roomState ,"&URLRoomName=",URLRoomName)

  const isDisabled = isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching;

  return (
    <AppBar className={classes.container} position="fixed" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <h3 style={{ paddingLeft: 10 }}>{isConnecting || isFetching ? connectingLabel : ""}</h3>
        {/* {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />} */}
        {roomState === "disconnected" && !URLUserName ? (
          <form id="join-form" className={classes.form} onSubmit={handleSubmit}>
            {window.location.search.includes("customIdentity=true") || !user?.displayName ? (
              <Input
                id="menu-name"
                placeholder={inputPlaceholder}
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
                margin="dense"
              />
            ) : null}
            {/* <TextField
              id="menu-room"
              label="Room ID"
              className={classes.textField}
              value={roomName}
              onChange={handleRoomNameChange}
              margin="dense"
            /> */}

            <Button
              className={isDisabled ? classes.disabledButton : classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              disabled={isDisabled}>
              {btnJoinLabel}
            </Button>
          </form>
        ) : null}
        {/* <Menu /> */}
        {/* <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu />
        </div> */}
      </Toolbar>
      <Dialog
        open={isDialogOpen}
        onClose={handleSubmit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: { borderRadius: 10 },
        }}>
        {/* <DialogTitle style={{backgroundColor:'white'}} id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent style={{ backgroundColor: "#fff" }}>
          <DialogContentText style={{ color: "black", textAlign: "center" }}>
            {joinAlert}
          </DialogContentText>
        </DialogContent>
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "20px",
          }}>
          <Button onClick={handleOk} variant="outlined" color="secondary" autoFocus>
            OK
          </Button>
        </div>
      </Dialog>
    </AppBar>
  );
}
