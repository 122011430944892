import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import Tooltip from "@material-ui/core/Tooltip";

import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  }),
);

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { t } = useTranslation();
  const mute = t("Mute");
  const unmute = t("Unmute");

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip
        title={isAudioEnabled ? mute : unmute}
        placement="top"
        PopperProps={{ disablePortal: true }}>
        <Fab
          className={classes.fab}
          style={{
            backgroundColor: "rgba(255,255,255,0.04)",
            border: "1px solid",
            borderColor: "rgba(255,255,255,0.2)",
          }}
          onClick={toggleAudioEnabled}
          disabled={props.disabled}
          data-cy-audio-toggle>
          {isAudioEnabled ? (
            <img
              src={require("../../../assets/icons/iconFunctionMuteDefault24@2x.png")}
              alt=""
              style={{ width: "1.5em", height: "1.5em" }}
            />
          ) : (
            <img
              src={require("../../../assets/icons/iconFunctionMuteSelected24@2x.png")}
              alt=""
              style={{ width: "1.5em", height: "1.5em" }}
            />
          )}
        </Fab>
      </Tooltip>
      <div style={{ fontSize: "0.8em", fontWeight: 500 }}>{mute}</div>
    </div>
  );
}
