import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./RotateDevice.css";

export default function RotateDevice() {
  const [isLandscape, setIsLandscape] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    function doOnOrientationChange() {
      switch (window.orientation) {
        case -90:
          //  document.getElementById("landscape").style.display="block";
          setIsLandscape(true);
          break;
        case 90:
          setIsLandscape(true);
          // document.getElementById("landscape").style.display="block";
          break;
        default:
          setIsLandscape(false);
          // document.getElementById("landscape").style.display="none";
          break;
      }
    }
    //Listen to orientation change
    window.addEventListener("orientationchange", doOnOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", doOnOrientationChange);
    };
  }, []);
  if (isLandscape) {
    return (
      <div id="landscape">
        <div>{t("Please Rotate Device to Portrait")}</div>
      </div>
    );
  }
  return null;
}
