import React from "react";
import { styled } from "@material-ui/core/styles";
import useHeight from "../../hooks/useHeight/useHeight";
import RotateDevice from "./RotateDevice";
function formatDate(objDate: Date, fmt: string) {
  var o: any = {
    "M+": objDate.getMonth() + 1, //月份
    "d+": objDate.getDate(), //日
    "h+": objDate.getHours() % 12 == 0 ? 12 : objDate.getHours() % 12, //小时
    "H+": objDate.getHours(), //小时
    "m+": objDate.getMinutes(), //分
    "s+": objDate.getSeconds(), //秒
    "q+": Math.floor((objDate.getMonth() + 3) / 3), //季度
    S: objDate.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (objDate.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length),
      );
  return fmt;
}

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
  paddingBottom: "23em",
}));

export default function InvalidParam({
  message,
  startTime,
  endTime,
}: {
  message?: string;
  startTime?: string;
  endTime?: string;
}) {
  const height = useHeight();
  // let interviewTime = "";
  // const intStartTime = parseInt(startTime || "0", 10);
  // const intEndTime = parseInt(endTime || "0", 10);
  // if (startTime && endTime) {
  //   const startDate = formatDate(new Date(intStartTime), "yyyy-MM-dd HH:mm:ss");
  //   const endDate = formatDate(new Date(intEndTime), "yyyy-MM-dd HH:mm:ss");
  //   interviewTime = `${startDate} - ${endDate}`;
  // }

  return (
    <>
      <RotateDevice />
      <Container style={{ height }}>
        <img
          src={require("../../assets/icons/picTime@2x.png")}
          alt=""
          style={{ width: "10.3em", height: "12.0em" }}
        />
        <div
          style={{
            fontSize: "1.1em",
            fontWeight: 400,
            color: "rgb(133,133,133)",
            marginTop: "0.8em",
          }}>
          {message}
        </div>
        {/* {interviewTime && <div>{interviewTime}</div>} */}
      </Container>
    </>
  );
}
