import React from "react";
import { styled } from "@material-ui/core/styles";
import useHeight from "../../hooks/useHeight/useHeight";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "flexEnd",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
}));

export default function UnsupportedBrowser() {
  const height = useHeight();

  return (
    <Container style={{ height }}>
      <img
        src={require("../../assets/icons/open-in-browser-2.png")}
        alt="not supported browser"
        style={{ width: "100%", maxWidth: 480 }}
      />
    </Container>
  );
}
