import React from "react";
import Participant from "../Participant/Participant";
import { styled } from "@material-ui/core/styles";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useWidth from '../../hooks/useWidth/useWidth';

const Container = styled("aside")(({ theme }) => ({
  padding: "0.5em",
  overflowY: "auto",
  zIndex: 1,
  position: "absolute",
  top: "2em",
  [theme.breakpoints.down("sm")]: {
    overflowY: "initial",
    overflowX: "auto",
    padding: 0,
    display: "flex"
  }
}));

const ScrollContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "flex"
  }
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant }
  } = useVideoContext();
  const participants = useParticipants();
  const [
    selectedParticipant,
    setSelectedParticipant
  ] = useSelectedParticipant();

  const fullWidth = useWidth()

  return (
    <Container>
      <ScrollContainer style={{width: fullWidth}}>
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant}
          onClick={() => setSelectedParticipant(localParticipant)}
        />
        
        {participants.map(participant => (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant}
            onClick={() => setSelectedParticipant(participant)}
          />
        ))}
      </ScrollContainer>
    </Container>
  );
}
